import React, { useState } from 'react';

import { COLORS } from '../../services/configuration';

import './index.css';

function Legend(props) {
  const [locked, setLocked] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const {
    config = {},
    metricType = 'contact',
    onChange,
  } = props;

  const handleChange = (min, max) => (event) => {
    if (!locked) {
      onChange(min, max);
    }
  }

  const handleClick = (index) => (event) => {
    if (locked) {
      setSelectedIndex(null);
      onChange(null, null);
    } else {
      setSelectedIndex(index);
    }

    setLocked(!locked);
  }

  const renderLegend = () => {
    return config.LEGEND_KEYS.map((key, index) => (
      <div 
        className={['legend-item', selectedIndex === index ? 'selected' : null].join(' ')}
        key={`legend-item-${index}`}
        onClick={handleClick(index)}
        onMouseEnter={handleChange(config.LEGEND_VALUES[index][0], config.LEGEND_VALUES[index][1])}
        onMouseLeave={handleChange(null, null)}
      >
        <span className="legend-key" style={{backgroundColor: COLORS[metricType][index]}}></span>
        <span className="legend-value">{renderValue(config.LEGEND_VALUES[index])}</span>
      </div>
    ));
  }

  const renderValue = (array) => {
    if (array[1] === Infinity) {
      return `${array[0]}+`;
    } else {
      return array.join('-');
    }
  }

  return (
    <div
      className="legend"
      onMouseLeave={handleChange([null, null])}
    >
      <strong>Probability Sum</strong>

      {renderLegend()}
    </div>
  );
}

export default Legend;