import React, { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import {
  FormControlLabel, 
  Radio, 
  RadioGroup,
  Switch
} from '@material-ui/core';

import { findByAbbreviation, findByNumber } from '../../services/states';
import { byZoom, CONFIG_STRATEGIES } from '../../services/configuration';
import ContactGraphs from '../../components/ContactGraphs';
import Legend from '../../components/Legend';
import DateNavigation from '../../components/DateNavigation';

import './index.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiZGF0YXBhbmRlbW9zIiwiYSI6ImNrZjJ6Y2s2cjFtMHMyem1iY2dmeWFjd3QifQ.iWZmh4Y3x_0D0vDQDqM8PQ';

const YYYY_MM_DD = "yyyy'-'MM'-'dd";

function ExplorerSection(props) {
  const [map, setMap] = useState(null);
  const [config, setConfig] = useState(null);
  const [mapReady, setMapReady] = useState(false);
  const [layers, ] = useState(new Set());
  const [legendMin, setLegendMin] = useState(null);
  const [legendMax, setLegendMax] = useState(null);
  const [metricType, setMetricType] = useState('contact');
  const [search, setSearch] = useState({
    state: 'CT',
    stateName: 'Connecticut',
    region: 'Place',
    string: 'Hartford',
    metric: metricType,
  });
  const [selectedDateString, setSelectedDateString] = useState('2021-06-08');
  const [isSharing, setSharing] = useState(false);
  const [sources, setSources] = useState([{
    id: 'Healthcare-POIs',
    layer: 'Healthcare-POIs.layer',
    url: '/geo_files/Healthcare-POIs.geojson',
    icon: 'hospital-15',
    checked: true,
  }, {
    id: 'Government-POIs',
    layer: 'Government-POIs.layer',
    url: '/geo_files/Government-POIs.geojson',
    icon: 'town-hall-15',
    checked: true,
  }, {
    id: 'Education-POIs',
    layer: 'Education-POIs.layer',
    url: '/geo_files/Education-POIs.geojson',
    icon: 'college-15',
    checked: true,
  }, {
    id: 'PublicVenue-POIs',
    layer: 'PublicVenue-POIs.layer',
    url: '/geo_files/PublicVenue-POIs.geojson',
    icon: 'marker-15',
    checked: true,
  }]);

  const mapContainerRef = useRef(null);

  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
  });

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [-72.7725, 41.575],
      zoom: 8,
      minZoom: CONFIG_STRATEGIES[0].MIN,
      maxZoom: CONFIG_STRATEGIES[CONFIG_STRATEGIES.length - 1].MAX,
      preserveDrawingBuffer: true,
    });

    const bindObject = {
      config: byZoom(map.getZoom()),
      map: map,
    };

    map.on('click', handleMapClick.bind(bindObject));
    map.on('idle', handleMapIdle.bind(bindObject));
    map.on('load', handleMapLoad.bind(bindObject));
    map.on('mousemove', handleMapMousemove.bind(bindObject));
    map.on('mouseout', handleMapMouseout.bind(bindObject))
    map.on('zoom', handleMapZoom.bind(bindObject));

    setConfig(bindObject.config);
    setMap(map);

    return () => {
      map.remove();
      setMap(null);
      setConfig(null);
    }
  }, []);

  function buildFileName(imageType) {
    const date = new Date(selectedDateString.replace('-', '/'));
    return `data-pandemos-${buildFileNameMetricType(metricType)}-${imageType}-${format(date, YYYY_MM_DD)}.png`;
  }

  function buildFileNameMetricType(metric) {
    switch (metric) {
      case 'home':
        return 'primary-dwell';
      case 'contact':
        return 'contact';
      default:
        throw new Error(`Explorer.buildFileNameMetricType metric unknown ${metric}`)
    }
  }

  function filterBy(dateAsString, min, max, metric) {
    const filters = CONFIG_STRATEGIES.reduce((acc, strategy) => {
      const expression = [];

      expression.push(['==', ['downcase', ['get', 'contact_type']], metric]);

      if (strategy.TILE_SOURCE === config.TILE_SOURCE) {
        if (min != null && max != null) {
          expression.push([">=", ['to-number', ['get', 'prob_sum']], min]);
    
          if (max !== Infinity){
            expression.push(["<", ['to-number', ['get', 'prob_sum']], max]);
          }  
        }
      }

      expression.unshift('all');
      
      acc.push({
        layer: `${strategy.TILE_SOURCE}.layer`,
        expression: expression,
      });

      return acc;
    }, []);


    filters.forEach((filter) => {
      map.setFilter(filter.layer, filter.expression);
    });

    setSelectedDateString(dateAsString);
  }

  function filterByPointsOfInterest(sourceList) {
    sourceList.forEach((s) => {
      map.setLayoutProperty(s.layer, 'visibility', s.checked === true ? 'visible' : 'none');
    });
  }

  const handleDownload = (fileName, dataUrl) => {
    var link = document.createElement('a');
    link.download = fileName;
    link.href = dataUrl;
    link.click();

    setSharing(false);
  }

  const handleExport = (nodeId, fileName) => (event) => {
    html2canvas(document.querySelector(nodeId))
      .then((canvas) => handleDownload(fileName, canvas.toDataURL()))
      .catch((error) => console.error('capture error', error));
  }

  const handleLegendChange = (min, max) => {
    setLegendMin(min);
    setLegendMax(max);
    
    filterBy(selectedDateString, min, max, metricType);
  }

  function stateMapFromLayer(feature) {
    switch (feature.layer.id) {
      case 'public.geo_states.layer':
        return findByAbbreviation(feature.properties.abbreviation);
      case 'public.geo_places.layer':
        return findByAbbreviation(feature.properties.state);
      case 'public.geo_census_block_groups.layer':
        return findByNumber(Number(feature.properties.state));
      default:
    }
  }

  function handleMapClick(event) {
    const features = this.map.queryRenderedFeatures(event.point, {
      layers: Array.from(layers),
    });

    const config = byZoom(this.map.getZoom());
    const stateMap = stateMapFromLayer(features[0]);

    if (features.length > 0 && stateMap) {
      setSearch({
        state: stateMap.abbreviation,
        stateName: stateMap.name,
        region: features[0].properties.geoid_type,
        string: features[0].properties[config.TILE_KEY],
        metric: metricType,
      });
    }
  }

  function handleMapIdle(event) {
    if (this.map.areTilesLoaded()) {
      setMapReady(true);
    }
  }

  function handleMapLoad() {

    // MVT api example
    // => http://<host>/api/vectortiles/<region>/<date>/<z>/<x>/<y>

    // Add map tiles
    // => States, Places, and Census Block Groups
    CONFIG_STRATEGIES.forEach((strategy) => {
      this.map.addSource(`${strategy.TILE_SOURCE}.source.${selectedDateString}`, {
        'tiles': [`${window.location.protocol}//${window.location.host}/api/vectortiles/${strategy.TILE_SOURCE}/${selectedDateString}/{z}/{x}/{y}`],
        'type': 'vector',
        'attribution': '<a href="http://www.whitespace-solutions.com">&copy; Whitespace LTD</a> | <a href="https://xmode.io/">&copy; X-Mode</a>',
      });

      this.map.addLayer({
        'id': `${strategy.TILE_SOURCE}.layer`,
        'source': `${strategy.TILE_SOURCE}.source.${selectedDateString}`,
        'source-layer': `${strategy.TILE_SOURCE}.source-layer`,
        'type': 'fill',
        'filter': ['==', ['downcase', ['get', 'contact_type']], metricType],
        'maxzoom': strategy.MAX,
        'minzoom': strategy.MIN,
        'paint': this.config.findPaintProperties(strategy.TILE_SOURCE),
      });

      layers.add(`${strategy.TILE_SOURCE}.layer`);
    });

    // Add geoJson features
    // => Points of Interest
    sources.forEach((source) => {
      this.config.createPointOfInterests(this.map, source);
      this.map.setLayoutProperty(source.layer, 'visibility', source.checked === true ? 'visible' : 'none');
      layers.add(source.layer);
    });

    this.map.addControl(
      new mapboxgl.ScaleControl({maxWidth: 400, unit: 'metric'}),
      'bottom-right'
    );
    this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
  }

  const noPopupToRender = (features) => {
    return features.length === 0 || (
      features[0].properties.prob_sum === null && !features[0].layer.source.endsWith("POIs")
    );
  }

  function handleMapMousemove(event) {
    if (this.map.areTilesLoaded()) {
      const features = this.map.queryRenderedFeatures(event.point, {
        layers: Array.from(layers),
      });

      if (noPopupToRender(features)) {
        this.map.getCanvas().style.cursor = '';
        popup.remove();

      } else {
        this.map.getCanvas().style.cursor = 'pointer';

        const description = [];
        const properties = features[0].properties;

        switch (properties.geoid_type) {
          case 'STATE':
            description.push(`<strong>${properties.name}</strong></br />`);
            description.push(`Contact Sum: ${properties.prob_sum}`);
            break;
          case 'PLACE':
            description.push(`<strong>${properties.geoid}, ${properties.state}</strong></br />`);
            description.push(`Contact Sum: ${properties.prob_sum}`);
            break;
          case 'CBG':
            description.push(`<strong>CBG ${properties.geoid} </strong></br />`);
            description.push(`Contact Sum: ${properties.prob_sum}`);
            break;
          default:
            if (properties.name) {
              description.push(`<strong>${properties.type || "Point of Interest"}</strong></br />`);
              description.push(properties.name === 'null' ? `<em>${properties.type} name unavailable</em>` : properties.name);
            }
        }

        popup.setLngLat(event.lngLat).setHTML(description.join(' ')).addTo(this.map);
      }
    }
  }

  function handleMapMouseout(event) {
    popup.remove();
  }

  function handleMapTypeOption(event) {
    event.preventDefault();

    setMetricType(event.target.value);
    setSearch({
      ...search,
      metric: event.target.value,
    })

    filterBy(selectedDateString, legendMin, legendMax, event.target.value);
  }

  function handleMapZoom() {
    setConfig(byZoom(this.map.getZoom()));

    // TODO: refactor
    handleChangeDate.bind(this)(new Date(selectedDateString.replace('-', '/')));
  }

  const handleSharing = (event) => {
    setSharing(!isSharing);
  }

  function handleChangeDate(date) {
    const dateAsString = format(date, YYYY_MM_DD);

    const source = `${this.config.TILE_SOURCE}.source.${dateAsString}`;
    const sourceLayer = `${this.config.TILE_SOURCE}.source-layer`;
    const layerId = `${this.config.TILE_SOURCE}.layer`;

    if (!this.map.getSource(source)) {
      this.map.addSource(source, {
        'tiles': [`${window.location.protocol}//${window.location.host}/api/vectortiles/${this.config.TILE_SOURCE}/${dateAsString}/{z}/{x}/{y}`],
        'type': 'vector',
        'attribution': '<a href="http://www.whitespace-solutions.com">&copy; Whitespace Solutions LLC</a> | <a href="https://xmode.io/">&copy; X-Mode</a>',
      });
    }

    if (!this.map.getLayer(`${this.config.TILE_SOURCE}_${dateAsString}`)) {

      const setLayerSource = (layerId, source, sourceLayer) => {
        const oldLayers = this.map.getStyle().layers;
        const layerIndex = oldLayers.findIndex((layer) => layer.id === layerId);
        const layerDef = oldLayers[layerIndex];
        const before = oldLayers[layerIndex + 1] && oldLayers[layerIndex + 1].id;
        layerDef.source = source;

        if (sourceLayer) {
          layerDef['source-layer'] = sourceLayer;
        } else if (sourceLayer !== undefined) {
          delete layerDef['source-layer'];
        }

        this.map.removeLayer(layerId);
        
        if (before) {
          this.map.addLayer(layerDef, before);
        } else {
          this.map.addLayer(layerDef);
        }
      }
      
      setLayerSource(layerId, source, sourceLayer);
    }

    setSelectedDateString(dateAsString);
  }

  const handleSourceChange = (source) => (event) => {
    const index = sources.findIndex((s) => s.id === source.id);
    let sourceList;

    if (index === 0) {
      sourceList = [
        {
          ...source,
          checked: event.target.checked,
        },
        ...sources.slice(1),
      ];
    } else if (index > 0) {
      sourceList = [
        ...sources.slice(0, index),
        {
          ...source,
          checked: event.target.checked,
        },
        ...sources.slice(index + 1),
      ];
    }

    filterByPointsOfInterest(sourceList);
    setSources(sourceList);
  }

  function renderMenu() {
    if (mapReady) {
      return (
        <div className="menu-wrapper">
          <div id="menu" className="menu">
            <div className="menu-header">
              <div className="name">Contact Metrics</div>
              <div className="actions">
                <span className="action">
                  <span className="material-icons md-18" onClick={handleSharing}>file_download</span>
                </span>
              </div>
            </div>

            <div className="menu-content">
              {isSharing && (
                <div className="share-types">
                  <div className="share-type" onClick={handleExport('.plot-box', buildFileName('chart'))}>
                    <span className="material-icons">assessment</span>
                    <span className="label">Chart</span>
                  </div>
                  <div className="share-type" onClick={handleExport('#map', buildFileName('map'))}>
                    <span className="material-icons">public</span>
                    <span className="label">Map</span>
                  </div>
                </div>
              )}

              <div>
                <RadioGroup onChange={handleMapTypeOption}>
                  <FormControlLabel
                    checked={metricType === 'contact'}
                    control={<Radio className="maptype-radio" color="primary" />}
                    label="Contact Location"
                    value="contact" />

                  <FormControlLabel
                    checked={metricType === 'home'}
                    control={<Radio className="maptype-radio" color="primary" />}
                    label="Primary Dwell Location"
                    value="home" />
                </RadioGroup>
              </div>

              <DateNavigation
                defaultDateString={selectedDateString}
                onChangeDate={handleChangeDate.bind({map: map, config: config})} />
            </div>
          </div>

          {map.getZoom() >= 10 && (
            <div className="menu poi-toggle">
              <div>
                <div className="menu-header">
                  <div className="name">Toggle POI Layers</div>
                </div>

                <div className="menu-content">
                  <div className="sources">
                    {sources.map((source) => (
                      <div key={`source-${source.id}`} className="source">
                        <FormControlLabel
                          control={<Switch
                            checked={source.checked}
                            color="primary"
                            onChange={handleSourceChange(source)} />}
                          label={source.id} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
          </div>
        )}
        </div>
      );
    } else {
      return <div id="loading" className="menu loading">Loading...</div>;
    }
  }

  // The object merge in the search value of the ContactGraphs initialization
  // is a clumbsy fix related to the metricType scoping when handleMapClick
  // is scoped. The metricType value inside of handleMapClick is not being
  // updated and holds the initial value it was set as in the useState
  // statement at the beginning of the file. This works, but should be
  // refactored when a fix is identified to make sure someone doesn't miss it.

  return (
    <div id="explorer">
      <div id="map-container" className="map-container">
        <div id="map" ref={mapContainerRef}>
        </div>

        {renderMenu()}

        {mapReady && (
          <Legend
            config={config}
            metricType={metricType}
            onChange={handleLegendChange} />
        )}

      </div>

      {mapReady && (
        <ContactGraphs search={{
          ...search,
          metric: metricType
        }} />
      )}
    </div>
  );
}

export default ExplorerSection;
