import React from 'react';

import './index.css';

function FAQSection(props) {
  return (
    <div>
      <div className="faq-content">
        <h2>FAQ</h2>

        <p><strong>What is the difference between Data Pandemos and &quot;digital contact tracing&quot;?</strong></p>

        <p>
          So-called &quot;digital contact tracing&quot; is a set of protocols for an individual device
          to report device IDs of its contacts after its owner is diagnosed with COVID-19. Messaging to
          those contacts encourages them to get tested and/or isolate. In contrast the Data Pandemos
          project gathers information about contact prospectively and aggregates this information
          spatially. We measure close contacts between devices as they happen, to predict future cases
          at the population level.
        </p>

        <p><strong>Why don't you use your methodology to conduct digital contact tracing, or better yet, track down who infected whom?</strong></p>
        <p>
          Three reasons: ethics, latency, and purpose. First, we want to gather epidemiologically
          useful information about population-level behavior without violating the privacy of individual
          device users. Second, most COVID-19 diagnoses don't happen until well after individuals become
          symptomatic, several days after infection. Many people never become symptomatic, and never seek
          testing. This means that contact tracing from diagnosed cases is often too slow or incomplete to
          prevent new infections before they occur. Third, our purpose is to provide aggregate measures of
          contact to help decision-makers -- in government and business -- predict where outbreaks and
          cases are likely to arise next.
        </p>
      </div>
    </div>
  );
}

export default FAQSection;