import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import Pagination from '@material-ui/lab/Pagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Typography from '@material-ui/core/Typography';
import {
  endOfWeek,
  format,
  isSameDay,
  startOfWeek,
  sub,
} from 'date-fns';

import './index.css';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'geoid', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'rank', numeric: true, disablePadding: false, label: 'Rank by week' },
  { id: 'probSumByDeviceDiff', numeric: true, disablePadding: false, label: '% change' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            className={classes.tableColumn}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  searchTextbox: {
    '& .MuiInputBase-input': {
      padding: 10,
    },
  },
  table: {
    minWidth: 750,
  },
  tableColumn: {
    fontWeight: 600,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('rank');
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, ] = React.useState(10);
  const [search, setSearch] = React.useState(null);

  const cellArrowFrom = (row) => {
    if (row.rank < row.rankDiff) {
      return <PlayArrowIcon className="trend-arrow trending-up" />;
    } else if (row.rank > row.rankDiff) {
      return <PlayArrowIcon className="trend-arrow trending-down" />;
    }
  }

  const probSumColorFrom = (row) => {
    if (row.probSumByDeviceDiff > 0) {
      return 'trending-up';
    } else if (row.probSumByDeviceDiff < 0) {
      return 'trending-down';
    } else {
      return '';
    }
  }

  const rankColorFrom = (value) => {
    if (value > 0) {
      return 'trending-up';
    } else if (value < 0) {
      return 'trending-down';
    } else {
      return '';
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleSearchEntry = (event) => {
    setPage(1);
    setSearch(event.target.value.length > 0 ? event.target.value : null);
  }

  const limitBySearch = (item) => {
    if (search) {
      return new RegExp(search, 'i').test(item.geoid);
    } else {
      return true;
    }
  }

  useEffect(() => {
    const formatString = "yyyy'-'MM'-'dd";
    const currentDate = new Date('2021/06/12');

    let dateString;

    if (isSameDay(currentDate, endOfWeek(currentDate))) {
      dateString = format(startOfWeek(currentDate), formatString);
    } else {
      dateString = format(sub(startOfWeek(currentDate, {weekStartsOn: 0}), {weeks: 1}), formatString);
    }

    fetch(`/api/v1/census-block-groups/contact/${dateString}`)
      .then((response) => {
        if (response) {
          response.json().then((rows) => setRows(rows));
        }
      });
  }, []);

  const offsetStart = () => {
    return page === 1 ?
      1 :
      ((page - 1) * rowsPerPage) + 1;
  }

  const offsetEnd = () => {
    const start = offsetStart();
    const rowsLength = rows.map(limitBySearch).length;

    return rowsLength > start + rowsPerPage ?
      start + rowsPerPage :
      rowsLength;
  }

  const paginationCount = () => {
    const rowsLength = rows.filter(limitBySearch).length;

    return parseInt(rowsLength / rowsPerPage);
  }

  return (
    <div className={classes.root}>
      <h2>Top Census Block Groups: {rows.length && format(new Date(rows[0].firstDate), "'for the week of 'PPPP")}</h2>

      <p>
        The table below shows contact scores for every census block group in Connecticut, in decreasing order. Also displayed are the ranks (1 for highest) this week and last week. Sort by percent change to show trending block groups.
      </p>

      <div className="subheader-search">
        <Typography variant="subtitle1">
        Search: 
        </Typography>
        <TextField
          id="census-block-groups-search"
          classes={{
            root: classes.searchTextbox,
          }}
          onChange={handleSearchEntry}
          variant="outlined" />
      </div>

      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows.filter(limitBySearch), getComparator(order, orderBy))
              .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow key={`row-${row.geoid}`} hover tabIndex={-1}>
                    <TableCell align="left" component="th" id={labelId} scope="row">
                      {row.geoid}
                    </TableCell>
                    <TableCell align="right" className={rankColorFrom(row.rankDiff - row.rank)}>
                      <div className="rank">
                        {cellArrowFrom(row)}
                        <span>was {row.rankDiff} is {row.rank}</span>
                      </div>
                    </TableCell>
                    <TableCell className={probSumColorFrom(row)} align="right">
                      {row.probSumByDeviceDiff.substring(0, 12)}
                    </TableCell>
                  </TableRow>
                );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="pagination wrapper">
        <div className="entries">
          Showing {offsetStart()} to {offsetEnd()} of {rows.filter(limitBySearch).length} entries
        </div>

        <div className="options">
          <Pagination
            count={paginationCount()}
            defaultPage={1}
            page={page}
            onChange={handleChangePage}
            shape="rounded"
            size="large"
            variant="outlined" />
        </div>
      </div>
    </div>
  );
}
