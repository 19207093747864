import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Header from '../components/Header';
import AboutSection from '../sections/About';
import FAQSection from '../sections/FAQ';
import CensusBlockGroupRanking from '../sections/CensusBlockGroupRanking';
import TownRanking from '../sections/TownRanking';
import ExplorerSection from '../sections/Explorer';

import './index.css';

function App(props) {
  return (
    <div>
      <Header />

      <div className="sections">
        <BrowserRouter>
          <Switch>
            <Route path="/about">
              <AboutSection />
            </Route>
            <Route path="/frequently-asked-questions">
              <FAQSection />
            </Route>
            <Route path="/census-block-group-rankings">
              <CensusBlockGroupRanking />
            </Route>
            <Route path="/town-rankings">
              <TownRanking />
            </Route>
            <Route path="/explorer">
              <ExplorerSection />
            </Route>
            <Route path="/">
              <ExplorerSection />
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;