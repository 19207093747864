import React from 'react';

import './index.css';

function AboutSection(props) {
  return (
    <div>
      <div className="about-content">
        <h2>About</h2>

        <p><strong>Why Contact Metrics?</strong></p>
        
        <p>
          Close social contact is the primary way that COVID-19 spreads from person to person,
          but until now, there was no way to measure it at the level of an entire population.
          &nbsp;<strong><a href="https://www.whitespace-solutions.com/" target="_new">Whitespace’s</a>
          &nbsp;close contact metrics are a good predictor of the emergence of COVID-19 cases before
          they are detected by testing.</strong> They can get healthcare providers, policymakers,
          and business leaders far enough ahead of new infections to make lifesaving decisions.
          You can use this dashboard to explore and compare trends in close social contact
          across different regions. We update the metrics weekly.
        </p>

        <p><strong>Computing the Metrics</strong></p>

        <p>
          We use anonymized device location data provided by
          &nbsp;<a href="https://xmode.io/" target="_new">X-Mode Social</a> to identify close social
          contact events (when 2 mobile devices are within 2 meters – approximately 6 feet –
          of one another). Because even the highest quality device location data comes with
          substantial uncertainty, we use a statistical algorithm for computing the probability
          that two devices are within two meters.
        </p>

        <p><strong>Exploring the Metrics</strong></p>

        <div>
          <p>
            We display daily close social contact measurements aggregated to regional, state, county
            (or town), and census block group (CBG) levels. You can toggle between these views by zooming
            in and out of the map and view through daily metrics using the time slider to see changes in
            contact patterns indicating:
          </p>

          <ol>
            <li>Where contact is occurring, and</li>
            <li>Where cases are likely to emerge in the future.</li>
          </ol>
        </div>

        <p>
          The default view, &quot;Contact Location&quot; (purple) shows areas where close contacts occurred
          on a given day. This is useful for understanding areas within a CBG, county, or state where
          people’s devices are coming into close social contact.
        </p>

        <p>
          The &quot;Primary Dwell Location&quot; (green) view shows the same data visualized by the regions where
          the devices that have been in close contact with others live. This is useful for understanding
          communities where higher-contact individuals appear to reside, and subsequently, where you can
          expect new cases to emerge in the future.
        </p>

        <p><strong>Privacy</strong></p>

        <p>
          This dashboard reports metrics that are calculated using anonymized device
          location data collected transparently with user consent. The metrics are aggregated at CBG,
          county (or town), and state levels, and adhere to differential privacy standards. No individually
          identifiable location information can be derived from these metrics.
        </p>

        <p><strong>The Data Pandemos Project</strong></p>

        <p>
          Back in March 2020, we thought that if we could measure where people were getting too close
          to each other we could help leaders monitor and boost the effectiveness of physical distancing
          policies within their jurisdictions and save lives. We teamed with
          &nbsp;<a href="http://www.crawfordlab.io/" target="_new">Dr. Forrest W. Crawford</a>, a mathematician
          and statistician advising the <a href="https://portal.ct.gov/dph" target="_new">Connecticut
          Department of Public Health</a>, and professor of biostatistics in the
          &nbsp;<a href="https://www.yale.edu/" target="_new">Yale</a>
          &nbsp;<a href="https://publichealth.yale.edu/" target="_new">School of Public Health</a>, to vet
          our idea. Together, we developed a method for processing anonymized device location data using
          spatial data analytics.
        </p>

        <p>
          We named our project Data Pandemos, meaning &quot;data of/for all the people&quot;.
        </p>

        <p>
          In August 2020, we began delivering contact metrics to the
          &nbsp;<a href="https://portal.ct.gov/dph" target="_new">Connecticut Department of Public Health</a>.
          Shortly thereafter, <a href="http://www.crawfordlab.io/" target="_new">Dr. Crawford</a> began
          working to validate these metrics. This chapter of the story exceeded our expectations.
          &nbsp;<a href="http://www.crawfordlab.io/" target="_new">Dr. Crawford</a> established that contact
          metrics predict new cases of COVID-19 before they can be detected by testing. This could get
          healthcare providers, policymakers, and business leaders far enough ahead of new infections
          to make lifesaving decisions. So, we decided to take steps to raise awareness about our
          contact metrics and the Data Pandemos dashboard was born.
        </p>

        <p>
          In September 2020, we asked our dear friends, the AI/ML experts at
          &nbsp;<a href="https://sentrana.com/" target="_new">Sentrana</a>, to design and implement a more
          scalable architecture for our contact metrics pipeline so that we can process data for the
          entire country – or even world – each day. As the newest member of the Data Pandemos team,
          &nbsp;<a href="https://sentrana.com/" target="_new">Sentrana</a> is having tremendous success in
          speeding up time-intensive spatial processing that underpins our calculations, first on CPUs,
          and next GPUs. With their technical contributions, we plan to publish contact metrics for
          the entire U.S. via this dashboard beginning in early 2021.
        </p>
      </div>
    </div>
  );
}

export default AboutSection;