import React, { useState, } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { addDays, format, isValid } from 'date-fns'

import './index.css';

const YYYY_MM_DD = "yyyy'-'MM'-'dd";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function DateNavigation(props) {
  const {
    defaultDateString,
    onChangeDate,
  } = props;

  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState(new Date(defaultDateString.replace('-', '/')));
  const [backwardDisabled, ] = useState(false);
  const [forwardDisabled, ] = useState(false);

  function handleChangeDate(event) {
    const date = new Date(event.target.value.replace('-', '/'));
 
    if (isValid(date) && date > new Date('2020-01-01')) {
      onChangeDate(date, selectedDate);
      setSelectedDate(date);
    }
  }

  function handleSliderBackward(event) {
    event.preventDefault();

    const date = addDays(selectedDate, -1);

    if (isValid(date)) {
      onChangeDate(date, selectedDate);
      setSelectedDate(date);
    }
  }

  function handleSliderForward(event) {
    event.preventDefault();

    const date = addDays(selectedDate, 1);

    if (isValid(date)) {
      onChangeDate(date, selectedDate);
      setSelectedDate(date);
    }
  }

  return (
    <div className="date-navigation-options">
      <div className="date-actions">
        <input
          alt="back arrow"
          className="arrow-navigation"
          disabled={backwardDisabled}
          onClick={handleSliderBackward}
          src="./resources/back-arrow.png"
          type="image" />

        <TextField
          id="date"
          className={classes.textField}
          onChange={handleChangeDate}
          InputLabelProps={{
            shrink: true,
          }}
          type="date"
          value={format(selectedDate, YYYY_MM_DD)}
        />

        <input
          alt="forward arrow"
          className="arrow-navigation"
          disabled={forwardDisabled}
          onClick={handleSliderForward}
          src="./resources/forward-arrow.png"
          type="image" />
      </div>
    </div>
  );
}

export default DateNavigation;