const ZOOM_BOUNDARIES = {
  'public.geo_states': {MIN: 3, MAX: 7},
  'public.geo_places': {MIN: 7, MAX: 10},
  'public.geo_census_block_groups': {MIN: 10, MAX: 15},
};

const LEGEND_KEYS = {
  'public.geo_states': [0, 1000, 2000, 3000, 4000, 5000],
  'public.geo_places': [0, 10, 20, 30, 40, 50],
  'public.geo_census_block_groups': [0, 1, 2, 3, 4, 5],
};

export const CONFIG_STRATEGIES = [{
  MIN: ZOOM_BOUNDARIES['public.geo_states'].MIN,
  MAX: ZOOM_BOUNDARIES['public.geo_states'].MAX,
  LEGEND_KEYS: LEGEND_KEYS['public.geo_states'],
  LEGEND_VALUES: [[0, 1000], [1000, 2000], [2000, 3000], [3000, 4000], [4000, 5000], [5000, Infinity]],
  OPACITY: 0.75,
  OUTLINE_COLOR: '#000',
  REGION: 'State',
  TILE_KEY: 'state',
  TILE_SOURCE: 'public.geo_states',
}, {
  MIN: ZOOM_BOUNDARIES['public.geo_places'].MIN,
  MAX: ZOOM_BOUNDARIES['public.geo_places'].MAX,
  LEGEND_KEYS: LEGEND_KEYS['public.geo_places'],
  LEGEND_VALUES: [[0, 10], [10, 20], [20, 30], [30, 40], [40, 50], [50, Infinity]],
  OPACITY: 0.75,
  OUTLINE_COLOR: '#000',
  REGION: 'Place',
  TILE_KEY: 'geoid',
  TILE_SOURCE: 'public.geo_places',
}, {
  MIN: ZOOM_BOUNDARIES['public.geo_census_block_groups'].MIN,
  MAX: ZOOM_BOUNDARIES['public.geo_census_block_groups'].MAX,
  LEGEND_KEYS: LEGEND_KEYS['public.geo_census_block_groups'],
  LEGEND_VALUES: [[0.0, 1.0], [1.0, 2.0], [2.0, 3.0], [3.0, 4.0], [4.0, 5.0], [5.0, Infinity]],
  OPACITY: 0.75,
  OUTLINE_COLOR: '#000',
  REGION: 'CBG',
  TILE_KEY: 'geoid',
  TILE_SOURCE: 'public.geo_census_block_groups',
}];

export const COLORS = {
  CONTACTCBG: ['#ffffff', '#e9e2ff', '#d2c4ff', '#bba6ff', '#a588ff', '#8e6afe'],
  contact: ['#ffffff', '#e9e2ff', '#d2c4ff', '#bba6ff', '#a588ff', '#8e6afe'],
  HOMECBG: ['#ffffff', '#d1e6d7', '#a7d1b4', '#7dbc91', '#53a76e', '#2a924a'],
  home: ['#ffffff', '#d1e6d7', '#a7d1b4', '#7dbc91', '#53a76e', '#2a924a'],
};

const findLegendKey = (region, index) => LEGEND_KEYS[region][index];

const createLines = (map, source, layer, dataUrl) => {
  map.addSource(source, {
    data: dataUrl,
    type: 'geojson',
  });
  map.addLayer({
    id: layer,
    source: source,
    minzoom: ZOOM_BOUNDARIES['public.geo_census_block_groups'].MIN,
    maxzoom: ZOOM_BOUNDARIES['public.geo_census_block_groups'].MAX,
    type: 'line',
    paint: {
      'line-color': 'rgba(0, 0, 0, 1)',
      'line-width': 1
    },
  });  
};

const createPointOfInterests = (map, source) => {
  map.addSource(source.id, {
    data: source.url,
    type: 'geojson',
  });
  map.addLayer({
    id: source.layer,
    source: source.id,
    minzoom: ZOOM_BOUNDARIES['public.geo_census_block_groups'].MIN,
    maxzoom: ZOOM_BOUNDARIES['public.geo_census_block_groups'].MAX,
    type: 'symbol',
    layout: {
      'icon-image': source.icon,
      'icon-size': 1.25,
    },
  });
};

export function byZoom(level) {
  const config = CONFIG_STRATEGIES.find((strategy) => {
    return level >= strategy.MIN && level < strategy.MAX;
  }) || {
    LEGEND_KEYS: [],
    LEGEND_VALUES: [],
  };

  // CREATORS
  config.createLines = createLines;
  config.createPointOfInterests = createPointOfInterests;

  // FINDERS

  // Draw color by expression match
  // Comparisions:
  //   prob_sum <=> legend value
  //   contact type <=> CONTACT or HOME
  config.findFillColorExpression = (source) => {
    return [
      'step',
      ['to-number', ['get', 'prob_sum']],
      [
        'case', // 0
        ['==', ['get', 'contact_type'], 'CONTACT'],
        COLORS.contact[0],
        COLORS.home[0],
      ],
      LEGEND_KEYS[source][1], [
        'case', // 1
        ['==', ['get', 'contact_type'], 'CONTACT'],
        COLORS.contact[1],
        COLORS.home[1],
      ],
      LEGEND_KEYS[source][2], [
        'case', // 2
        ['==', ['get', 'contact_type'], 'CONTACT'],
        COLORS.contact[2],
        COLORS.home[2],
      ],
      LEGEND_KEYS[source][3], [
        'case', // 3
        ['==', ['get', 'contact_type'], 'CONTACT'],
        COLORS.contact[3],
        COLORS.home[3],
      ],
      LEGEND_KEYS[source][4], [
        'case', // 4
        ['==', ['get', 'contact_type'], 'CONTACT'],
        COLORS.contact[4],
        COLORS.home[4],
      ],
      LEGEND_KEYS[source][5], [
        'case', // 5
        ['==', ['get', 'contact_type'], 'CONTACT'],
        COLORS.contact[5],
        COLORS.home[5],
      ],
    ];
  }

  config.findLegendKey = findLegendKey;

  config.findPaintProperties = (source) => {
    return {
      'fill-color': config.findFillColorExpression(source),
      'fill-opacity': config.OPACITY,
      'fill-outline-color': config.OUTLINE_COLOR,
    }
  }

  return config;
}