const states = require('./states.json');

const abbreviationMap = states.reduce(function(acc, state) {
  acc[state.abbreviation] = state;
  return acc;
}, {});

const codeMap = states.reduce(function(acc, state) {
  acc[Number(state.code)] = state;
  return acc;
}, {});


export function findByAbbreviation(value) {
  return abbreviationMap[value.toUpperCase()];
}

export function findByNumber(value) {
  return codeMap[value];
}