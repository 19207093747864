import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import * as d3 from 'd3';

import D3Chart from '../../d3Chart';

import './index.css';

function ContactGraphs(props) {
  const {
    search = {},
  } = props;

  const [visible, setVisible] = useState(false);
  const stateChart = useRef(null);
  const selectionChart = useRef(null);

  useEffect(() => {
    async function fetchDataFromApi() {
      const response = await fetch(`/api/v1/charts/state/${search.state}/${search.metric}`);
      const data = await response.json();

      if (data.length > 0) {
        stateChart.current = new D3Chart("contacts-plot-top", {
          title: `${search.stateName} - ${chartMetric(search.metric)}`,
          title30: `${search.stateName} (Last 30 days)`,
          domain: data
        }, {
          lineColor: '#00002c',
        });
      }
    }

    fetchDataFromApi();

    return () => {
      d3.select('#contacts-plot-top').selectAll('*').remove();
      d3.select('#contacts-plot-top-mini').selectAll('*').remove();
    };
  }, [search.state, search.stateName, search.metric]);

  useEffect(() => {
    async function fetchDataFromApi() {
      const response = await fetch(`/api/v1/charts/${search.region}/${search.string}/${search.metric}`);
      const data = await response.json();

      if (data.length > 0) {
        selectionChart.current = new D3Chart("contacts-plot-bottom", {
          title: `${search.string} ${chartMetric(search.metric)}`,
          title30: `${search.string} (Last 30 days)`,
          domain: data,  
        }, {
          lineColor: search.metric === 'contact' ? '#8e6afe' : '#2a924a',
        });
      }
    }

    fetchDataFromApi();

    return () => {
      d3.select('#contacts-plot-bottom').selectAll('*').remove();
      d3.select('#contacts-plot-bottom-mini').selectAll('*').remove();
    };
  }, [search.region, search.string, search.metric]);


  const chartMetric = (metric) => {
    switch (metric) {
      case 'contact':
        return 'Contact Location';
      case 'home':
        return 'Primary Dwell Location';
      default:
        throw new Error(`ContactGraphs.chartMetric unknown metric ${metric}`);
    }
  }

  return (
    <>
      <div id="show-plot" className="show-plot" onClick={(event) => setVisible(!visible)}>
        Click to show Contact Graphs
      </div>

      <motion.div id="plot-box"
        animate={{ left: visible ? 0 : -810 }}
        className="plot-box"
        onClick={(event) => setVisible(!visible)}
        style={{ left: -810 }}
        transition={{duration: 0.5}}
      >
        <div id="plot-storage" className="plot-storage">
          <div
            id="contacts-plot-top"
            className="contacts-plot-top" />

          <div
            id="contacts-plot-top-mini"
            className="contacts-plot-top mini" />

          <div
            id="contacts-plot-bottom"
            className="contacts-plot-bottom" />

          <div
            id="contacts-plot-bottom-mini"
            className="contacts-plot-bottom mini" />
        </div>
      </motion.div>
    </>
  );
}

export default ContactGraphs;