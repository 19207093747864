import React from 'react';

import './index.css';

const HEADERS = [
  { name: 'Explorer', path: 'explorer' },
  { name: 'Towns', path: 'town-rankings' },
  { name: 'Block Groups', path: 'census-block-group-rankings' },
  { name: 'About', path: 'about' },
  { name: 'FAQ', path: 'frequently-asked-questions' },
];

function Header(props) {
  return (
    <div className="header">
      <div className="header-logo">
        <img src="./resources/data-pandemos-logo.png" alt="Data Pandemos powered by Whitespace" />
      </div>

      <ul className="header-menu">
        {HEADERS.map((header, index) => (
          <li
            className="header-menu-item"
            key={`header-menu-item-${index}`}
          >
            <a className="link" href={header.path}>{header.name}</a>
          </li>
        ))}
        <li style={{flex: 10}}></li>
        <li className="header-menu-item navigation-tips">
          <button className="link">Navigation Tips</button>

          <div className="submenu">
            <h3>Contact Sum</h3>
            <ul>
              <li>Zoom out to see aggregation by U.S. state</li>
              <li>Zoom in to see aggregation by CBG</li>
            </ul>

            <h3>Regions</h3>
            <ul>
              <li>Mouseover a region to display contact sum</li>
              <li>Mouseover the legend to filter by range</li>
              <li>Click a color in the legend to lock filter by range</li>
              <li>Click a region to display in contact sum graph</li>
            </ul>

            <h3>Points of Interest</h3>
            <ul>
              <li>POIs will appear when zoomed in to CBG level</li>
              <li>POI groups can be toggled on and off</li>
              <li>Mouseover POIs for type and name</li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Header;