import * as d3 from 'd3';


class D3Chart {
  constructor(divId, map = {}, options) {
    this.divId = divId
    this.svg = null;
    this.svgMini = null;
    this.options = {
      lineColor: 'steelblue',
      ...options,
    };

    this.initializeChart(map);
  }

  updateChart(data) {
    const x = d3.scaleTime()
            .domain(d3.extent(data, (d) => d.date))
            .range([ 0, this.width ]);

    this.y
      .domain([0, d3.max(data, (d) => d.prob_sum) ]);

    this.svg
      .selectAll(".probSum")
      .transition()
      .duration(500)
      .call(this.yAxis);

    // Create a update selection: bind to the new data
    const u = this.svg
      .selectAll(".lineTest")
      .data([data], (d) => d.date);

    // Updata the line
    const y = this.y;

    u.enter()
      .append("path")
      .attr("class","lineTest")
      .merge(u)
      .transition()
      .duration(500)
      .attr("d", d3.line()
        .x((d) => x(d.date))
        .y((d) => y(d.prob_sum)))
        .attr("fill", "none")
        .attr("stroke", this.options.lineColor)
        .attr("stroke-width", 2.5);

      this.updateChartMini(data
        .slice(Math
          .max(data.length - 30, 1)));
  }

  updateChartMini(data = null) {
    var x = d3.scaleTime()
            .domain(d3.extent(data, (d) => d.date))
            .range([ 0, this.widthMini ]);

    // create the Y axis
    this.yMini.domain([0, d3.max(data, (d) => d.prob_sum) ]);
    this.svgMini
      .selectAll(".probSum")
      .transition()
      .duration(500)
      .call(this.yAxisMini);

    // Create a update selection: bind to the new data
    var u = this.svgMini
      .selectAll(".lineTest")
      .data([data], (d) => d.date);

    // Updata the line
    var y = this.yMini;

    u.enter()
      .append("path")
      .attr("class", "lineTest")
      .merge(u)
      .transition()
      .duration(500)
      .attr("d", d3.line()
        .x((d) => x(d.date))
        .y((d) => y(d.prob_sum)))
        .attr("fill", "none")
        .attr("stroke", this.options.lineColor)
        .attr("stroke-width", 2.5)
  }

  initializeChart(map) {
    const margin = {top: 20, right: 20, bottom: 20, left: 40};
    const chartDiv = document.getElementById(this.divId);

    this.width = chartDiv.clientWidth - margin.left - margin.right;
    this.height = chartDiv.clientHeight - margin.top - margin.bottom;

    // May need a fix to handle conversion from UTC to local time
    const data = map.domain
      .sort()
      .reduce((acc, row) => {
        acc.push({"date": d3.timeParse("%Y-%m-%d")(row.date), "prob_sum": row.prob_sum});

        return acc;
      }, []);

    const plot = d3.select(`#${this.divId}`);

    plot
      .append("div")
        .attr('class', 'title-text')
        .attr('id', `${this.divId}-title`)
      .text((d) => map.title)

    this.svg = plot
      .append('svg')
        .attr("width", this.width + margin.left + margin.right)
        .attr("height", this.height + margin.top + margin.bottom)
      .append("g")
        .attr("transform",
              "translate(" + margin.left + "," + margin.top + ")");

    // Initialise a X axis:
    const x = d3.scaleTime()
              .domain(d3.extent(data, (d) => d.date))
              .range([ 0, this.width ]);
    const xAxis = d3.axisBottom().scale(x).tickFormat(d3.timeFormat('%b %e'));

    this.svg.append("g")
      .attr("transform", "translate(0," + this.height + ")")
      .call(xAxis);

    // Initialize an Y axis
    this.y = d3.scaleLinear().range([this.height, 0]);
    this.yAxis = d3.axisLeft().scale(this.y).ticks(5);
    this.svg
      .append("g")
      .attr("class", "probSum");

    const chartDivMini = document.getElementById(`${this.divId}-mini`);

    this.widthMini = chartDivMini.clientWidth - margin.left - margin.right;
    this.heightMini = chartDivMini.clientHeight - margin.top - margin.bottom;

    const dataMini = data.slice(Math.max(data.length - 30, 1));
    const plotMini = d3.select(`#${this.divId}-mini`);

    plotMini
      .append("div")
        .attr('class', 'title-text')
        .attr('id', `${this.divId}-mini-title`)
      .text((d) => map.title30);

    this.svgMini = plotMini
      .append("svg")
        .attr("width", this.widthMini + margin.left + margin.right)
        .attr("height", this.heightMini + margin.top + margin.bottom)
      .append("g")
        .attr("transform",
              "translate(" + margin.left + "," + margin.top + ")");

    // // Initialise a X axis:
    const dataMiniXaxis = [dataMini[0], dataMini[dataMini.length - 1]];
    const xMini = d3.scaleTime()
              .domain(d3.extent(dataMiniXaxis, (d) => d.date))
              .range([0, this.widthMini]);
    const xAxisMini = d3.axisBottom().scale(xMini).ticks(6);

    this.svgMini.append("g")
      .attr("transform", "translate(0," + this.heightMini + ")")
      .call(xAxisMini);

    // // Initialize an Y axis
    this.yMini = d3.scaleLinear().range([this.heightMini, 0]);
    this.yAxisMini = d3.axisLeft().scale(this.yMini).ticks(5);
    this.svgMini.append("g")
      .attr("class","probSum");

    this.updateChart(data);
  }
}

export default D3Chart;